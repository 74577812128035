import React from "react";
import { PageHeader } from "antd";
import { Heading1 } from "./lolcss";

// displays a page header

export default function Header() {
  return (
    <></>
  );
}
